;
(function($){

$('.menu-handle').on('click', function() {
    console.log('lalalaal');
    $(this).find('i').toggleClass('arrow_carrot-left arrow_carrot-right');
    $(this).parents('.fixed-top').toggleClass('menu-closed');
    $('main').toggleClass('menu-closed');
});


$('.toggle-text .toggle-btn').on('click', function(e){
    showMoreLabel = $(this).parent().parent().find('.show-more-label') ? $(this).parent().parent().find('.show-more-label').html() : "Show more";
    showLessLabel = $(this).parent().parent().find('.show-less-label') ? $(this).parent().parent().find('.show-less-label').html() : "Show less";
    if($(this).parent().parent().find('.show-less').hasClass('d-none')){
       $(this).parent().parent().find('.show-less').removeClass('d-none');
       $(this).parent().parent().find('.show-all').addClass('d-none');
       $(this).html(showMoreLabel);
    }else{
       $(this).parent().parent().find('.show-all').removeClass('d-none');
       $(this).parent().parent().find('.show-less').addClass('d-none');
       $(this).html(showLessLabel);
    }
});
})(jQuery);
